import 'twin.macro';

import { Search } from '@cheqroom/icons';
import { Card, Heading, Icon, Input, Stack, Text } from '@cheqroom/ui';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomField, SystemField, SystemFieldType as ItemSystemFieldType } from '../../hooks/item-fields/types';
import { SystemField as CheckoutsSystemFieldType } from '../../pages/Checkouts/Overview/python.hooks';
import { SystemField as ReservationsSystemFieldType } from '../../pages/Reservations/Overview/python.hooks';
import ReorderFields from './ReorderFields';
import SelectFields from './SelectFields';

export type CustomizeColumnsProps = {
	type: 'items-list' | 'check-outs-list' | 'contacts-list' | 'kits-list' | 'reservations-list' | 'spotchecks-list';
	customFields: CustomField[];
	selectedColumnIds: string[];
	setSelectedColumnIds: (selectedColumnIds: string[]) => void;
} & (
	| {
			type: 'items-list';
			systemFields: SystemField<ItemSystemFieldType>[];
	  }
	| {
			type: 'reservations-list';
			systemFields: SystemField<ReservationsSystemFieldType>[];
	  }
	| {
			type: 'check-outs-list';
			systemFields: SystemField<CheckoutsSystemFieldType>[];
	  }
	| {
			type: 'contacts-list' | 'kits-list' | 'spotchecks-list';
			systemFields: SystemField<string>[];
	  }
);

const CustomizeColumns: FC<CustomizeColumnsProps> = ({
	type,
	systemFields,
	customFields,
	selectedColumnIds,
	setSelectedColumnIds,
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const { t } = useTranslation('customize-overview-columns');

	const handleToggleColumn = (id: string) => {
		if (selectedColumnIds.includes(id)) {
			setSelectedColumnIds(selectedColumnIds.filter((columnId) => columnId !== id));
			return;
		}
		setSelectedColumnIds([...selectedColumnIds, id]);
	};

	const handleRemoveColumn = (id: string) => {
		setSelectedColumnIds(selectedColumnIds.filter((columnId) => columnId !== id));
	};

	const isFieldSelected = (id: string) => selectedColumnIds.includes(id);

	const handleOnSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const systemFieldsFiltered = systemFields.filter((field) =>
		field.name.toLowerCase().includes(searchTerm.toLowerCase())
	);
	const customFieldsFiltered = customFields.filter((field) =>
		field.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const handleChangeColumnOrder = (fromIndex: number, toIndex: number) => {
		const newSelectedColumnIds = [...selectedColumnIds];
		newSelectedColumnIds.splice(toIndex, 0, newSelectedColumnIds.splice(fromIndex, 1)[0]);
		setSelectedColumnIds(newSelectedColumnIds);
	};

	const allFields = [...systemFields, ...customFields];

	return (
		<Card>
			<Card.Header>
				<Heading element="h4">{t('customize_columns.title')}</Heading>
				<Text size="small" color="subdued">
					{t(`customize_columns.description.${type}`)}
				</Text>
			</Card.Header>
			<Card.Section tw="p-0!">
				<Stack tw="grid grid-cols-1 md:grid-cols-2">
					<Stack vertical spacing="loose" tw="p-6 border-r">
						<Input
							prefix={<Icon source={Search} size="small" tw="text-gray-500" />}
							value={searchTerm}
							onChange={handleOnSearchTermChange}
							placeholder={t('customize_columns.search') ?? ''}
						/>
						<Stack vertical spacing="loose">
							<SelectFields
								title={t('customize_columns.system_fields')}
								fields={systemFieldsFiltered}
								isFieldSelected={isFieldSelected}
								onToggleColumn={handleToggleColumn}
							/>
							<SelectFields
								title={t('customize_columns.custom_fields')}
								fields={customFieldsFiltered}
								isFieldSelected={isFieldSelected}
								onToggleColumn={handleToggleColumn}
								isCustomFields
							/>
						</Stack>
					</Stack>
					<Stack tw="p-6">
						<ReorderFields
							selectedColumns={selectedColumnIds.map(
								(columnId) => allFields.find((field) => field.id === columnId)!
							)}
							changeColumnOrder={handleChangeColumnOrder}
							onRemoveColumn={handleRemoveColumn}
							blockBeforeIndex={0}
						/>
					</Stack>
				</Stack>
			</Card.Section>
		</Card>
	);
};

export default CustomizeColumns;
