import { useEventListener } from '@cheqroom/hooks';
import { Button, ButtonGroup, Modal, Text } from '@cheqroom/ui';
import { Variation } from '@cheqroom/ui/src/components/Button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useConfirmation from '../confirmation/useConfirmation';

export const useNavigation = <Context,>({
	canDeactivate,
	getModal,
	getContext,
}: {
	canDeactivate?: boolean | (() => boolean);
	getContext?: () => Promise<Context> | Context;
	getModal?: (context?: Context) => {
		title: string;
		body: string;
		actions: {
			primary: {
				text: string;
				onClick?: () => void;
				variation?: Variation;
				behaviour: 'confirm' | 'cancel';
			};
			secondary: {
				text: string;
				onClick?: () => void;
				variation?: Variation;
				behaviour: 'confirm' | 'cancel';
			};
		};
	};
} = {}) => {
	const { t } = useTranslation('navigate-warning-modal');
	const [forceAllowDeactivate, setForceAllowDeactivate] = useState(false);

	const navigate = (path: string, args?: { replace: boolean; trigger: boolean }) => {
		setForceAllowDeactivate(true);
		window.app.router.navigate(path, args);
	};

	const navigateBack = () => window.app.router.navigateBack();

	useEffect(() => {
		// Reset window.onDeactivate when the component unmounts
		return () => {
			window.onDeactivate = () => Promise.resolve(true);
		};
	}, []);

	const canDeactivateResult = typeof canDeactivate === 'function' ? canDeactivate() : canDeactivate;
	const shouldBlockDeactivation = canDeactivate !== undefined && !canDeactivateResult && !forceAllowDeactivate;

	useEventListener(
		'beforeunload',
		(event) => {
			event.preventDefault();
		},
		shouldBlockDeactivation
	);

	const [showDeactivateConfirmationModal] = useConfirmation<{ context: Awaited<Context> | undefined }>(
		({ onCancel, onConfirm, isShown, context }) => {
			const modal = getModal?.(context);

			const handleSecondaryClick = async () => {
				if (modal?.actions?.secondary.behaviour === 'cancel') {
					await onCancel();
				} else {
					await onConfirm();
				}
				modal?.actions?.secondary?.onClick?.();
			};

			const handlePrimaryClick = async () => {
				if (modal?.actions?.primary.behaviour === 'cancel') {
					await onCancel();
				} else {
					await onConfirm();
				}
				modal?.actions?.primary?.onClick?.();
			};

			return (
				<Modal
					open={isShown}
					header={<Modal.Header>{modal?.title || t('header.title')}</Modal.Header>}
					footer={
						<Modal.Footer>
							<ButtonGroup>
								<Button
									variation={modal?.actions?.secondary.variation ?? 'secondary'}
									onClick={handleSecondaryClick}
								>
									{modal?.actions?.secondary?.text || t('footer.confirm')}
								</Button>
								<Button
									variation={modal?.actions?.primary.variation ?? 'destructive'}
									onClick={handlePrimaryClick}
								>
									{modal?.actions?.primary?.text || t('footer.cancel')}
								</Button>
							</ButtonGroup>
						</Modal.Footer>
					}
				>
					<Modal.Body>
						<Modal.Section>
							<Text>{modal?.body || t('body.description')}</Text>
						</Modal.Section>
					</Modal.Body>
				</Modal>
			);
		}
	);

	const deactivate = async (): Promise<boolean> => {
		const context = getContext ? await getContext() : undefined;
		if (shouldBlockDeactivation) {
			return new Promise((resolve) =>
				showDeactivateConfirmationModal(() => resolve(true), {
					onClose: () => resolve(false),
					onCancel: () => resolve(false),
					context,
				})
			);
		}

		return Promise.resolve(true);
	};

	// Only set onDeactivate when canDeactivate is explicitly set. Otherwise it will unintentionally implicitly overrule explicitly set behaviour.
	if (canDeactivate !== undefined) {
		window.onDeactivate = deactivate;
	}

	return { navigate, navigateBack };
};

export default useNavigation;
