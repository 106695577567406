import 'twin.macro';

import { Search, Warning } from '@cheqroom/icons';
import { Card, Heading, Icon, Input, Stack, Text } from '@cheqroom/ui';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomField, Field, SystemField, SystemFieldType } from '../../hooks/item-fields/types';
import ItemPreview from './ItemPreview';
import ReorderFields from './ReorderFields';
import SelectFields from './SelectFields';

const CUSTOMIZE_ITEM_VIEW_SELECTION_LIMIT = 3;

export type CustomizeItemViewProps = {
	customFields: CustomField[];
	systemFields: SystemField<SystemFieldType>[];
	selectedFieldIds: string[];
	setSelectedFieldIds: (selectedFieldIds: string[]) => void;
};

const CustomizeItemView: FC<CustomizeItemViewProps> = ({
	systemFields,
	customFields,
	selectedFieldIds,
	setSelectedFieldIds,
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const { t } = useTranslation('customize-overview-columns');

	const handleToggleColumn = (id: string) => {
		if (selectedFieldIds.includes(id)) {
			setSelectedFieldIds(selectedFieldIds.filter((columnId) => columnId !== id));
			return;
		}
		setSelectedFieldIds([...selectedFieldIds, id]);
	};

	const handleRemoveField = (id: string) => {
		setSelectedFieldIds(selectedFieldIds.filter((columnId) => columnId !== id));
	};

	const isFieldSelected = (id: string) => selectedFieldIds.includes(id);

	const handleOnSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const systemFieldsFiltered = systemFields.filter((field) =>
		field.name.toLowerCase().includes(searchTerm.toLowerCase())
	);
	const customFieldsFiltered = customFields.filter((field) =>
		field.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const handleChangeFieldOrder = (fromIndex: number, toIndex: number) => {
		const newselectedFieldIds = [...selectedFieldIds];
		newselectedFieldIds.splice(toIndex, 0, newselectedFieldIds.splice(fromIndex, 1)[0]);
		setSelectedFieldIds(newselectedFieldIds);
	};

	const allFields = [...systemFields, ...customFields];

	const getFieldById = (id: string) => allFields.find((field) => field.id === id);

	const disabled = selectedFieldIds.length >= CUSTOMIZE_ITEM_VIEW_SELECTION_LIMIT;

	return (
		<Card>
			<Card.Header>
				<Heading element="h4">{t('customize_item_view.title')}</Heading>
				<Text size="small" color="subdued">
					{t('customize_item_view.description')}
				</Text>
			</Card.Header>
			<Card.Section tw="p-0!">
				<Stack tw="grid grid-cols-1 md:grid-cols-2">
					<Stack vertical spacing="loose" tw="p-6 border-r">
						<Input
							prefix={<Icon source={Search} size="small" tw="text-gray-500" />}
							value={searchTerm}
							onChange={handleOnSearchTermChange}
							placeholder={t('customize_item_view.search') ?? ''}
						/>
						<Stack vertical spacing="loose">
							<SelectFields
								title={t('customize_item_view.system_fields')}
								fields={systemFieldsFiltered}
								isFieldSelected={isFieldSelected}
								onToggleColumn={handleToggleColumn}
								disabled={disabled}
							/>
							<SelectFields
								title={t('customize_item_view.custom_fields')}
								fields={customFieldsFiltered}
								isFieldSelected={isFieldSelected}
								onToggleColumn={handleToggleColumn}
								disabled={disabled}
								isCustomFields
							/>
						</Stack>
						{disabled && (
							<Text tw="text-xs text-yellow-900">
								<Icon source={Warning} size="small" tw="text-yellow-500 pr-1" />
								{t('customize_item_view.max_3_warning')}
							</Text>
						)}
					</Stack>
					<Stack vertical spacing="loose" tw="p-6">
						<ItemPreview fieldIds={selectedFieldIds} getFieldById={getFieldById} />
						<ReorderFields
							selectedColumns={
								selectedFieldIds.map((columnId) =>
									allFields.find((field) => field.id === columnId)
								) as Field<SystemFieldType>[]
							}
							changeColumnOrder={handleChangeFieldOrder}
							onRemoveColumn={handleRemoveField}
						/>
					</Stack>
				</Stack>
			</Card.Section>
		</Card>
	);
};

export default CustomizeItemView;
