import 'twin.macro';

import { Button, ButtonGroup, Heading, Modal, Stack } from '@cheqroom/ui';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomField, SystemField, SystemFieldType as ItemSystemFieldType } from '../../hooks/item-fields/types';
import { ModalProps } from '../../hooks/modal/use-modal';
import { SystemField as CheckoutsSystemFieldType } from '../../pages/Checkouts/Overview/python.hooks';
import { SystemField as ReservationsSystemFieldType } from '../../pages/Reservations/Overview/python.hooks';
import CustomizeColumns from './CustomizeColumns';
import CustomizeItemView from './CustomizeItemView';

export type Props = {
	type: 'items-list' | 'check-outs-list' | 'contacts-list' | 'kits-list' | 'reservations-list' | 'spotchecks-list';
	viewType: 'table' | 'cards' | 'scheduler';
	systemFields: SystemField<any>[];
	customFields: CustomField[];
	selectedColumns: string[];
	saveConfig: (selectedColumns: string[], selectedFields?: string[]) => void;
	selectedFields?: string[];
} & (
	| {
			type: 'items-list';
			systemFields: SystemField<ItemSystemFieldType>[];
			selectedFields: string[];
			saveConfig: (selectedColumns: string[], selectedFields: string[]) => void;
	  }
	| {
			type: 'reservations-list';
			systemFields: SystemField<ReservationsSystemFieldType>[];
	  }
	| {
			type: 'check-outs-list';
			systemFields: SystemField<CheckoutsSystemFieldType>[];
	  }
	| {
			type: 'contacts-list' | 'kits-list' | 'spotchecks-list';
			systemFields: SystemField<string>[];
	  }
);

const CustomizeOverviewModal: FC<ModalProps & Props> = ({
	type,
	viewType,
	onClose,
	systemFields,
	customFields,
	selectedColumns,
	saveConfig,
	selectedFields = [],
}) => {
	const [selectedColumnIds, setSelectedColumnIds] = useState<string[]>(selectedColumns);
	const { t } = useTranslation('customize-overview-columns');

	const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>(selectedFields);

	const handleSaveChangesClick = () => {
		if (type === 'items-list') {
			saveConfig(selectedColumnIds, selectedFieldIds);
			window.app.trigger('items:changed');
		} else {
			saveConfig(selectedColumnIds);
		}

		onClose();
	};

	// Exclude name from item view system fields
	const customizeItemViewSystemFields = systemFields.filter((field) => field.id !== 'name');

	return (
		<Modal
			size="full-page"
			open
			header={
				<Modal.Header onClose={onClose}>
					<Heading element="h1">{t(`title.${type}`)}</Heading>
				</Modal.Header>
			}
			footer={
				<Modal.Footer sticky>
					<ButtonGroup>
						<Button variation="secondary" onClick={onClose}>
							{t('actions.cancel')}
						</Button>
						<Button variation="primary" onClick={handleSaveChangesClick}>
							{t('actions.save')}
						</Button>
					</ButtonGroup>
				</Modal.Footer>
			}
		>
			<Modal.Body>
				<Stack vertical spacing="loose">
					{viewType === 'table' && (
						<CustomizeColumns
							type={type}
							systemFields={systemFields}
							customFields={customFields}
							selectedColumnIds={selectedColumnIds}
							setSelectedColumnIds={setSelectedColumnIds}
						/>
					)}
					{type === 'items-list' && (
						<CustomizeItemView
							systemFields={customizeItemViewSystemFields as SystemField<ItemSystemFieldType>[]}
							customFields={customFields}
							selectedFieldIds={selectedFieldIds}
							setSelectedFieldIds={setSelectedFieldIds}
						/>
					)}
				</Stack>
			</Modal.Body>
		</Modal>
	);
};

export default CustomizeOverviewModal;
