import tw, { styled } from 'twin.macro';

import { Props } from './Image';

type StyledProps = Pick<Props, 'fit' | 'onClick'>;

const getFit = (fit?: StyledProps['fit']) => {
	switch (fit) {
		default:
		case 'contain':
			return tw`object-contain`;
		case 'cover':
			return tw`object-cover`;
		case 'fill':
			return tw`object-fill`;
		case 'none':
			return tw`object-none`;
		case 'scale-down':
			return tw`object-scale-down`;
	}
};

const getOnClick = (onClick?: unknown) => (onClick ? tw`hover:cursor-pointer hover:opacity-75` : undefined);

export const StyledImage = styled.img<StyledProps>(({ fit, onClick }) => [
	tw`w-full h-full`,
	getFit(fit),
	getOnClick(onClick),
]);
