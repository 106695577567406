/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { QueryClient, QueryFunction, QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import Authentication from '../services/authentication';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
		},
	},
});

/* 
	The query function context is needed to pass the AbortSignal to the fetch function.
	only the query function context has the signal property (since we can't abort a pending mutation).
*/
export const fetchGraphQLEndpoint = <TData, TVariables>(
	query: string,
	variables?: TVariables
): ((context?: QueryFunctionContext) => Promise<TData>) => {
	return async (context) => {
		const isQuery = !!context;

		const res = await fetch(process.env.GRAPHQL_URL as string, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${await Authentication.getAccessToken()}`,
			},
			body: JSON.stringify({
				query,
				variables,
			}),
			signal: isQuery ? context?.signal : null,
		});

		const json = await res.json();

		if (json.errors && !json.data) {
			const { message } = json.errors[0] || 'Error..';
			throw new Error(message as string);
		}

		return { ...json.data, extensions: json.extensions };
	};
};

export const fetchPythonEndpoint = <TData, TBody = BodyInit>(
	endpoint: string,
	body?: TBody,
	method: 'GET' | 'POST' = 'POST',
	anonymous = false
): (() => Promise<TData>) => {
	return async () => {
		let res;

		if (method === 'GET') {
			const url = new URL(`${process.env.API_URL as string}/${endpoint}`);
			Object.entries(body || {}).forEach(([key, value]) => {
				url.searchParams.append(key, value as string);
			});

			res = await fetch(url, {
				method,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					...(!anonymous ? { authorization: `Bearer ${await Authentication.getAccessToken()}` } : null),
				},
			});
		} else {
			res = await fetch(`${process.env.API_URL as string}/${endpoint}`, {
				method,
				headers: {
					...(body instanceof FormData ? null : { 'Content-Type': 'application/json' }),
					...(!anonymous ? { authorization: `Bearer ${await Authentication.getAccessToken()}` } : null),
				},
				...(body && { body: body instanceof FormData ? body : JSON.stringify(body) }),
			});
		}

		const json = await res.json();

		if (json.httpError) {
			throw new Error(json.httpError as string);
		}

		return json;
	};
};

// `fetch` doesn't support easy way for progress events for file uploads, so we need to use `XMLHttpRequest` instead
export const uploadFile = async <TData>(
	endpoint: string,
	formData: FormData,
	progressCallback?: (progressEvent: ProgressEvent) => void
): Promise<TData> => {
	const xhr = new XMLHttpRequest();
	xhr.open('POST', `${process.env.API_URL as string}/${endpoint}`, true);
	xhr.setRequestHeader('Accept', '*/*');
	xhr.setRequestHeader('authorization', `Bearer ${await Authentication.getAccessToken()}`);
	xhr.upload.onprogress = (progressEvent) => {
		progressCallback?.(progressEvent);
	};

	return await new Promise((resolve, reject) => {
		xhr.onload = () => {
			if (xhr.status >= 200 && xhr.status < 300) {
				resolve(JSON.parse(xhr.responseText) as TData);
			} else {
				reject(new Error(`Error: ${xhr.status} - ${xhr.statusText}`));
			}
		};
		xhr.onerror = () => reject(new Error('Network error occurred'));
		xhr.send(formData);
	});
};

export const fetchQueryWithCacheFallback = async <QueryData>(
	queryKey: QueryKey,
	queryFn: QueryFunction
): Promise<QueryData> => {
	try {
		// Attempt to fetch fresh data
		const data = await queryClient.fetchQuery({
			queryKey,
			queryFn: queryFn,
		});
		return data as QueryData;
	} catch (error) {
		// Fetching failed, fallback to cached data if available
		const cachedData = queryClient.getQueryData<QueryData>(queryKey);
		if (cachedData) {
			// eslint-disable-next-line no-console
			console.warn('Fetch failed, returning cached data:', error);
			return cachedData;
		}
		// eslint-disable-next-line no-console
		console.error('Fetch failed and no cached data available:', error);
		throw error;
	}
};
