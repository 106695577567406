import { Check, Error, Info, Warning } from '@cheqroom/icons';
import { FC } from 'react';

import { Icon } from '../../../Icon/Icon';
import { Stack } from '../../../Stack/Stack';
import { Text } from '../../../Typography/Text/Text';
import { getIconColor, StyledSubheader } from './Subheader.styles';

export type Variation = 'success' | 'warning' | 'error' | 'info' | 'empty';

export interface Props {
	title: string;
	variation?: Variation;
}

const mapVariationToIcon = (variation: Variation) => {
	switch (variation) {
		case 'warning':
			return Warning;
		case 'error':
			return Error;
		case 'success':
			return Check;
		case 'empty':
			return null;
		case 'info':
		default:
			return Info;
	}
};

export const Subheader: FC<Props> = ({ title, variation = 'info', ...rest }) => {
	const icon = mapVariationToIcon(variation);

	return (
		<StyledSubheader justify="center" align="center" spacing="tight" variation={variation} {...rest}>
			{icon && <Icon source={icon} color={getIconColor(variation)} size="small" />}
			<Stack tw="w-full" vertical spacing="extraTight">
				{title && (
					<Text color="current" weight="current">
						{title}
					</Text>
				)}
			</Stack>
		</StyledSubheader>
	);
};
