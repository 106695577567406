export class NumberFormatter {
	static money(cents: number, decimals = 2, currency: Currency = 'USD', locale = 'en-US'): string {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency,
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
			localeMatcher: 'lookup',
		})
			.format(cents / 100)
			.replace(/^(\D+)/, '$1 ');
	}
}
