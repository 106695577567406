/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DateTime } from '@cheqroom/date-time';
import { DateTime as LuxonDateTime } from 'luxon';

export const isAMPM = (): boolean => window.global.central.hoursFormat().includes('a');

export class DateFormatter {
	static shortDate(date: DateTime): string {
		return date.getLuxonDate().toLocaleString(LuxonDateTime.DATE_SHORT);
	}

	static shortDateTime(date: DateTime): string {
		return date.getLuxonDate().toLocaleString(LuxonDateTime.DATETIME_SHORT);
	}

	static abbreviatedDate(date: DateTime): string {
		return date.getLuxonDate().toLocaleString(LuxonDateTime.DATE_MED);
	}

	static abbreviatedDateWithWeekDay(date: DateTime): string {
		return date.getLuxonDate().toLocaleString(LuxonDateTime.DATE_MED_WITH_WEEKDAY);
	}

	static abbreviatedDateTimeWithWeekDay(
		date: DateTime,
		options: {
			setZoneUTC?: boolean;
		}
	): string {
		return LuxonDateTime.fromJSDate(date.toDate(), { zone: options.setZoneUTC ? 'utc' : undefined }).toLocaleString(
			{
				...LuxonDateTime.DATETIME_MED_WITH_WEEKDAY,
				hourCycle: isAMPM() ? 'h12' : 'h23',
			}
		);
	}

	static fullDate(date: DateTime): string {
		return date.getLuxonDate().toLocaleString(LuxonDateTime.DATE_FULL);
	}

	static fullDateWithWeekDay(date: DateTime): string {
		return date.getLuxonDate().toLocaleString(LuxonDateTime.DATE_HUGE);
	}

	static time(date: DateTime): string {
		return isAMPM() ? date.toFormat('hh:mm a') : date.toFormat('HH:mm');
	}

	static relative(date: DateTime): string | null {
		return date.getLuxonDate().toRelative();
	}
}
