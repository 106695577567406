export type CustomField = {
	id: CustomFieldType;
	name: string;
	selected: boolean;
	removable: boolean;
};

export type SystemField<Type extends string> = {
	id: Type;
	name: string;
	selected: boolean;
	removable: boolean;
};

export type Field<Type extends string> = CustomField | SystemField<Type>;

export enum SystemFieldType {
	brand = 'brand',
	model = 'model',
	qr = 'qr',
	barcode = 'barcode',
	purchaseDate = 'purchaseDate',
	purchasePrice = 'purchasePrice',
	warrantyDate = 'warrantyDate',
	flag = 'flag',
	contact = 'contact',
	kit = 'kit',
	location = 'location',
	quantity = 'quantity',
	category = 'category',
	created = 'created',
	expired = 'expired',
	address = 'address',
	residualValue = 'residualValue',
}

export type CustomFieldType = `fields.${string}`;

export type FieldType = SystemFieldType | CustomFieldType;
