import tw, { styled } from 'twin.macro';

import { Props, Size, Variation } from './Button';

export interface StyledProps extends Pick<Props, 'size' | 'variation' | 'inverted' | 'pressed'> {
	isLoading: boolean;
}

const getVariation = (variation?: Variation, pressed = false) => {
	switch (variation) {
		default:
		case 'primary':
			return tw`text-white hover:text-white focus:text-white bg-indigo-500 border-indigo-500 hover:bg-indigo-700 hover:border-indigo-700 active:bg-indigo-900 active:border-indigo-900 focus:ring-indigo-100 disabled:bg-gray-200 disabled:border-gray-200 disabled:text-gray-500`;
		case 'secondary':
			if (pressed) return tw`text-gray-700 border border-gray-300 bg-gray-100 ring-gray-100`;
			return tw`text-gray-700 hover:text-gray-700 focus:text-gray-700 bg-white border border-gray-300 bg-white hover:bg-gray-50 active:bg-gray-100 disabled:border-gray-200 focus:ring-gray-100 disabled:bg-gray-200 disabled:border-gray-200 disabled:text-gray-500`;
		case 'destructive':
			return tw`text-white hover:text-white focus:text-white bg-red-500 border-red-500 hover:bg-red-700 hover:border-red-700 active:bg-red-900 active:border-red-900 focus:ring-red-100 disabled:bg-gray-200 disabled:border-gray-200 disabled:text-gray-500`;
	}
};

const getInvertedVariation = (variation?: Variation) => {
	switch (variation) {
		default:
		case 'primary':
			return tw`bg-transparent border-transparent text-indigo-700 hover:text-indigo-700 focus:text-indigo-700 hover:bg-gray-50 hover:border-gray-50 active:bg-gray-100 active:border-gray-100 focus:ring-indigo-100 disabled:text-gray-400`;
		case 'secondary':
			return tw`bg-transparent border-transparent text-gray-700 hover:text-gray-700 focus:text-gray-700 hover:bg-gray-50 hover:border-gray-50 active:bg-gray-100 active:border-gray-100 focus:ring-gray-100 disabled:text-gray-400`;
		case 'destructive':
			return tw`bg-transparent border-transparent text-red-700 hover:text-red-700 focus:text-red-700 hover:bg-gray-50 hover:border-gray-50 active:bg-gray-100 active:border-gray-100 focus:ring-red-100 disabled:text-gray-400`;
	}
};

const getSize = (size?: Size) => {
	switch (size) {
		case 'extra-small':
			return tw`px-2 py-0`;
		case 'small':
			return tw`px-3 py-1`;
		default:
		case 'medium':
			return tw`px-4 py-2`;
		case 'large':
			return tw`px-8 py-[10px]`;
	}
};

export const sharedStyledButtonStyled = ({ isLoading, size, variation, inverted, pressed }: StyledProps) => [
	getSize(size),
	tw`flex items-center justify-center gap-2`,
	// Typography
	tw`whitespace-nowrap font-semibold border`,
	// Base
	tw`box-content rounded-md appearance-none select-none relative hover:no-underline focus:no-underline`,
	// Disabled
	tw`disabled:pointer-events-none disabled:shadow-none`,
	// Focus
	tw`focus:outline-none focus:ring-4`,
	// Transitions
	tw`transition`,

	// Variations
	inverted ? getInvertedVariation(variation) : getVariation(variation, pressed),

	isLoading ? tw`cursor-wait select-none` : tw`cursor-pointer`,
];

export const StyledButton = styled.button<StyledProps>(({ isLoading, size, variation, inverted, pressed }) => [
	sharedStyledButtonStyled({ isLoading, size, variation, inverted, pressed }),
]);

type StyledContentProps = {
	isLoading: boolean;
	size: 'small' | 'medium' | 'large';
};

const getContentSize = (size?: Size) => {
	switch (size) {
		case 'extra-small':
			return tw`text-xs leading-4`;
		case 'small':
			return tw`text-sm leading-5`;
		case 'medium':
			return tw`text-sm leading-5`;
		case 'large':
		default:
			return tw`text-base leading-6`;
	}
};

export const StyledButtonContent = styled.span<StyledContentProps>(({ isLoading, size }) => [
	getContentSize(size),
	tw`flex items-center justify-center gap-2`,
	tw`transition-opacity`,
	isLoading ? tw`opacity-0` : tw`opacity-100`,
]);
