import 'twin.macro';

import { DetailedHTMLProps, FC, HTMLAttributes, ReactElement, ReactNode } from 'react';

import { StyledHeader } from './Header.styles';
import { Subheader } from './Subheader';

interface ComponentComposition {
	Subheader: typeof Subheader;
}

export interface Props extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, 'ref'> {
	children?: ReactNode;
	subheaders?: ReactElement<typeof Subheader>[] | ReactElement<typeof Subheader>;
}

const Header: FC<Props> & ComponentComposition = ({ children, subheaders, ...rest }) => (
	<StyledHeader>
		<div tw="w-full" {...rest}>
			{children}
		</div>
		{subheaders}
	</StyledHeader>
);

Header.Subheader = Subheader;

export { Header };
